// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authRedirectUri: '/',
  authPostLogoutRedirectUri: '/',
  datexApplicationApiUrl: '/api/',
  integrationHubConfigApiUrl: 'https://integrationhub-configuration-qa.azurewebsites.net/configapi/',
  integrationHubConfigurationApiScopes: ['api://6270aed0-a332-4cde-b676-b6f6267147d4/.default'],
  legacyConnectorsConfigApiUrl: 'https://datex-file-watcher-configuration-api-qa.azurewebsites.net/',
  legacyConnectorsConfigApiScopes: ['api://542d189e-dd86-4316-a36a-77a779d17741/.default'],
  integrationHubApiUrl: 'https://api-qa.datexintegrationhub.com/api/',
  integrationHubApiScopes: ['api://b02d93e9-7c88-4a3d-87d8-5e9dad386f32/.default'],
  integrationHubLoggingApiUrl: 'https://integrationhub-logging-qa.azurewebsites.net/hubapi/',
  integrationHubLoggingApiScopes: ['api://1f13a31f-bcb4-4ec6-bb09-b57aec88e9d6/.default'],
  datexAlertApiUrl: 'https://datex-alert-services-qa.azurewebsites.net/odata/',
  datexAlertApiScopes: ['api://aca0e1e7-0a87-4ce2-b98e-0dfce8aa4ae4/.default'],
  datexAlertRecommndedSolutionApiUrl: 'https://ca-alerts-suggestion-api-qa.ambitioustree-9be13291.eastus.azurecontainerapps.io/',
  datexAlertRecommndedSolutionApiScopes: ['api://251e5db6-bef0-41da-8db4-67f3035ff5cc/.default'],
  kustoQueryApiUrl: '',
  kustoQueryApiScopes: [],
  footPrintAdaptorApiUrl: 'https://datexintegrationhub-footprint-qa.azurewebsites.net/api/',
  footPrintAdaptorApiScopes: ['api://266491f6-9fb1-4cb1-b3bb-56aefd699e86/.default'],
  shopifyAdaptorApiUrl: 'https://datexintegrationhub-shopify-qa.azurewebsites.net/api/',
  shopifyAdaptorApiScopes: ['api://266491f6-9fb1-4cb1-b3bb-56aefd699e86/.default'],
  wooCommerceAdaptorApiUrl: 'https://datexintegrationhub-woocommerce-qa.azurewebsites.net/api/',
  wooCommerceAdaptorApiScopes: ['api://266491f6-9fb1-4cb1-b3bb-56aefd699e86/.default'],
  bigCommerceAdaptorApiUrl: 'https://datexintegrationhub-bigcommerce-qa.azurewebsites.net/api/',
  bigCommerceAdaptorApiScopes: ['api://266491f6-9fb1-4cb1-b3bb-56aefd699e86/.default'],
  mesciusLicenseKey: '*.wavelength.host,232864259645381#B14TeF5RIp4MypGOpdlYCFnUtZlMZhkRwJUd5E4Z5wUY4hTR8FDSZdmeqhTSXNXM5YGR6FWM6YzVJdTS0JUaslEbWhFb5czdt5EaFdHckRVQ5RFRH3yM73yLXhGeMpWeFZ4ZShmdIFGVrYWMDNWS6hHdVl7S7U5Z5skdqF4UxpkRxE4NChGa5o5L5U4bBpkMQlzZrR4Q9ETdZBlb8l4d0hncSF4YQlWMtJkdhlWRrYTNplWcylleRZXRwlWTWxWcQxWelh6VMhEcatEMHNDdIFFb65WUXFUOMJkYLJWQZJDOCR7QaZTVQFEUmljSnBFWiojITJCLiQTNwQUN4kjNiojIIJCLzgTO9QjMyYzM0IicfJye35XX3JiVMtkTiojIDJCLiUjVgMlS4J7bwVmUlZXa4NWQiojIOJyebpjIkJHUiwiIxATNyITMggjMwEDNyAjMiojI4J7QiwiI4N7bo9Ca4dmblxWZ6F6duoiI0IyctRkIsICelRXYEJiOiEmTDJCLiEDOzUDN6kTNyQjN8IzMyIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRp5U7UUW4Q6QhlUMKJVaI5Ee9lDVh5kS4UXRPh7T6I4LwVXSrZDa4ZVbLZWNnV6aUxGU8gzSrVXWIhzVC56c7AzbjhjWHNTNBVDTuVmYKpFOTR5crJldUBHSXJUZSZ7QEdzUI5zNKx',
  ediLogicAppTriggerUrl: 'https://as2test.datexintegrationhub.com',
  wavelengthAccountConnectorUrl: 'https://wavelength-connector-edi-qa.eastus.cloudapp.azure.com/',
  authtok: 'gJxjmNebq3g0M9XIEcXHa2PiODl8roVO',
  copilotApiUrl: 'https://copilot.wavelength.host/api/codecopilot/analyze/',
  copilotApiScopes: ['api://b24fc21e-e3f5-48c1-9769-77ed844e7b66/.default'],
  icmApiUrl: 'https://icm.wavelength.host/odata/',
  icmApiScopes: ['api://ad5d6ff0-895d-4d96-aa09-62ddc99c68de/.default'],
  ediPublic: '',
  ediPrivate: '',
  datexDefaultAlertMessageTemplate: 'https://datexintegrationhubqa.blob.core.windows.net/datex-alert-services/html-email-templates/IntegrationHubDefault.html'
};
