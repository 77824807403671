import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageViewerComponent } from './image-viewer.component';
import { ImageViewerConfig } from './image-viewer-config.model';
import { ToggleFullscreenDirective } from './fullscreen.directive';


// copy code from 
// https://www.npmjs.com/package/ngx-image-viewer
// old library not maintained anymore and couldn't compile
// with ng16 update. Complaining about ModuleWithProviders
// https://angular.io/guide/migration-module-with-providers

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ImageViewerComponent,
    ToggleFullscreenDirective
  ],
  exports: [
    ImageViewerComponent,
    ToggleFullscreenDirective
  ]
})
export class ImageViewerModule {
  static forRoot(config?: ImageViewerConfig): ModuleWithProviders<ImageViewerModule> {
    return {
      ngModule: ImageViewerModule,
      providers: [{ provide: 'config', useValue: config }]
    };
  }
}
