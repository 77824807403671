import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'projects/datexapplication/src/environments/environment';
import { Observable } from 'rxjs';
import { DATEX_APP_API_URL } from '../common/services/application.service';

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {

  constructor(
    @Inject(DATEX_APP_API_URL) private apiUrl: string
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.wavelengthAccountConnectorUrl)) {

      const ediURL = btoa(unescape(encodeURIComponent(request.urlWithParams)));

      const modifiedReq = request.clone({
        url: `${this.apiUrl}EDIRelay?url=${ediURL}`
      });

      return next.handle(modifiedReq);
    }

    return next.handle(request);
  }
}
