import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { AuthorizationService } from "../services/application.service";
import { DatexErrorService } from "../../error/datex-error-service";
import { firstValueFrom } from "rxjs";

export const canViewStudio: CanActivateFn =
  async () => {
    const authService = inject(AuthorizationService);
    const router = inject(Router);
    const datexErrorService = inject(DatexErrorService);

    const hasStudioAccess = await firstValueFrom(authService.hasStudioAccess());
    if (!hasStudioAccess) {
      const errorMesssage = `You do not have permission.`;
      datexErrorService.add(new Error(errorMesssage));
      return router.createUrlTree(["/", "error"]);
    }
  }
