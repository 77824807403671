import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ErrorDialogComponent, IErrorDialogComponentData } from './error-dialog.component';
import { DatexErrorInfo } from './datex-error-info';
import { DatexErrorService } from './datex-error-service';

@Injectable()
export class DatexErrorHandler extends ErrorHandler {
  private readonly errorDialogId = 'BA6DF506-CC7F-495E-B5D2-25AE879187E9';
  private processingChangeDetectionError = false;

  constructor(protected injector: Injector) {
    super();
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  override handleError(error: any) {
    // call base handleError which will log in the console
    super.handleError(error);
    try {
      // try to unwrap the error, because zone.js wraps (by default) uncaught promise errors, see 
      // https://github.com/angular/angular/issues/27840
      if (error.promise && error.rejection) {
        error = error.rejection;
      }
      if (!error) {
        return;
      }

      if (this.isMSALError(error) || this.isAbortError(error)) {
        // swallow - do nothing, MSAL will handle 401/403 responses
      } else {
        const errorInfo = new DatexErrorInfo(error);

        // we need the NgZone to run inside it, because this method (handleError of ErrorHandler)
        // is usually called within a zone.runOutsideAngular
        const ngZone = this.injector.get(NgZone);
        const router = this.injector.get(Router);
        const dialog = this.injector.get(MatDialog);

        if (this.isChangeDetectionError(error)) {
          if (this.processingChangeDetectionError) {
            return;
          }
          this.processingChangeDetectionError = true;
          const errorsService = this.injector.get(DatexErrorService);
          errorsService.add(error);
          ngZone.run(() => {
            // force close all dialogs, since they can be the source of the error
            dialog.closeAll();
            router.navigateByUrl('/error');
          });
          setTimeout(() => {
            this.processingChangeDetectionError = false;
          }, 100);
        } else {
          // try and find an existing error dialog
          let errorDialogRef: MatDialogRef<ErrorDialogComponent>;
          if (dialog.openDialogs && dialog.openDialogs.length) {
            errorDialogRef = dialog.openDialogs.find(t => t.id === this.errorDialogId);
            // if componentInstance is null, the dialog has been closed already
            if (errorDialogRef && !errorDialogRef.componentInstance) {
              ngZone.run(() => {
                // just to be sure
                errorDialogRef.close();
              });
              errorDialogRef = null;
            }
          }

          if (errorDialogRef) {
            ngZone.run(() => {
              errorDialogRef.componentInstance.addMessage(errorInfo?.message, errorInfo?.detail);
            });
          } else {
            const modalConfig: MatDialogConfig = {
              id: this.errorDialogId,
              panelClass: 'popup-message',
              backdropClass: 'datex-backdrop',
              data: {
                title: 'General error',
                message: errorInfo.message,
                messageList: null,
                messageListDetailsSectionTitle: 'Technical details',
                messageListDetails: [{ message: errorInfo?.message, detail: errorInfo?.detail }]
              } as IErrorDialogComponentData
            };
            ngZone.run(() => {
              dialog.open(ErrorDialogComponent, modalConfig);
            });
          }
        }
      }
    } catch (e) {
      this.processingChangeDetectionError = false;
      console.error('Error while handling error inside ErrorsHandler:');
      console.error(e);
    }
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  isChangeDetectionError(error: any) {
    // SOME errors that happen during CD can cause an infinite error loop if we try to do anything
    // with the UI (because another CD cycle will start)
    // hence we want to specially handle those, but currently there isn't a good way to distinguish such errors, see:
    // https://github.com/angular/angular/issues/17010 , https://github.com/angular/angular/issues/38933
    return error instanceof TypeError;
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  isAbortError(error: any) {
    return error instanceof DOMException && error.name === 'AbortError';
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  isMSALError(error: any) {
    return error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403);
  }
}
