<div class="modal-container">
  <div mat-dialog-title
       class="modal-header">
    <h1 class="modal-title">{{data.title}}</h1>
  </div>
  <div mat-dialog-content
       class="modal-content">
    <div class="message-container">

      <div class="message-container">
        <div *ngIf="data.message"
             class="main-message">{{data.message}}</div>

        <ng-container *ngIf="data.messageList">
          <div class="message-list-item"
               *ngFor="let msg of data.messageList">
            {{ msg }}
          </div>
        </ng-container>

        <div *ngIf="hasMessageDetails()"
             class="message-details-container">
          <div *ngIf="hasMessageDetails() && data.messageListDetailsSectionTitle"
               (click)="toggleMessageListDetails = !toggleMessageListDetails"
               class="message-details-header {{toggleMessageListDetails ? 'details-open' : ''}}">
            {{data.messageListDetailsSectionTitle}}
            <div class="details-toggle">
              <i *ngIf="!toggleMessageListDetails"
                 class='icon icon-ic_fluent_chevron_down_20_filled'></i>
              <i *ngIf="toggleMessageListDetails"
                 class='icon icon-ic_fluent_chevron_up_20_filled'></i>
            </div>
          </div>

          <div class="message-details-dropdown"
               *ngIf="hasMessageDetails() && toggleMessageListDetails">
            <div class="message-details"
                 *ngFor="let detail of messageListDetails; let i = index">
              <div class="message-detail-title">{{ detail.message }}</div>
              <!-- <div class="message-detail-content">{{detail.detail}}</div> -->
              <div class="message-detail-content"
                   *ngIf="detail.detail">
                Error:
                <json-formatter [json]="messageListDetailsJSON[i]"
                                [expand]="true"></json-formatter>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
  <div mat-dialog-actions
       class="modal-toolbar commands">
    <button mat-button
            class="datex-button primary"
            (click)="onCloseClicked()">Close</button>
  </div>
</div>