import { Directive, Input, ElementRef, OnChanges } from '@angular/core';
import JSONFormatter from 'json-formatter-js';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'json-formatter'
})
export class JsonformatterDirective implements OnChanges {

  @Input() json: unknown;
  @Input() expand: boolean;

  constructor(private elRef: ElementRef) { }

  ngOnChanges(): void {
    if (this.json) {
      const formatter = new JSONFormatter(this.json);
      while (this.elRef.nativeElement.hasChildNodes()) {
        this.elRef.nativeElement.removeChild(this.elRef.nativeElement.lastChild);
      }
      this.elRef.nativeElement.appendChild(formatter.render());
      if (this.expand) {
        formatter.openAtDepth(Infinity);
      } else {
        formatter.openAtDepth(0);
      }
    }
  }

}
