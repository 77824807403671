<ng-container *ngIf="isLoggedIn; else notLoggedIn">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #notLoggedIn>
  <div *ngIf="!authenticating"
       class="full-screen-center">
    <button class="login-button"
            (click)="onLoginClicked()">LOGIN</button>
  </div>
  <div *ngIf="authenticating"
       class="full-screen-center">
    <div class="centered-content"> AUTHENTICATING <mat-progress-bar mode="buffer"></mat-progress-bar>
    </div>
  </div>
</ng-template>