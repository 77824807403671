import { Injectable } from '@angular/core';
import { Application, ApplicationDefinition, ApplicationSetting, ModuleApplicationSettingMap, Organization } from './application.do';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, ReplaySubject, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationContextService {
  private _applicationDefinitionId: number;
  private _applicationDefinition: ApplicationDefinition;
  private _applicationId: number;
  private _application: Application;
  private _applicationSettings: ApplicationSetting[];
  private _modulesApplicationSettingsMap: ModuleApplicationSettingMap[];
  private _applicationIdObservable = new ReplaySubject<number>(1);
  private _applicationDefinitionIdObservable = new ReplaySubject<number>(1);
  private _applicationObservable = new ReplaySubject<Application>(1);
  private _applicationSettingsObservable = new ReplaySubject<ApplicationSetting[]>(1);

  public currentOrganization$ = new BehaviorSubject<Organization>(null);
  public applicationIdObservable = this._applicationIdObservable.pipe(distinctUntilChanged());
  public applicationDefinitionIdObservable = this._applicationDefinitionIdObservable.pipe(distinctUntilChanged());
  public applicationObservable = this._applicationObservable.pipe(distinctUntilChanged());
  public applicationSettingsObservable = this._applicationSettingsObservable.pipe(distinctUntilChanged());

  get applicationDefinitionId() {
    return this._applicationDefinition ? this._applicationDefinition.id : this._applicationDefinitionId;
  }

  set applicationDefinitionId(value: number) {
    this._applicationDefinitionId = value;
    this._applicationDefinitionIdObservable.next(value);
  }

  get applicationDefinition(): ApplicationDefinition {
    return cloneDeep(this._applicationDefinition);
  }
  set applicationDefinition(value: ApplicationDefinition) {
    this._applicationDefinition = value;
    this._applicationDefinitionIdObservable.next(value?.id);
  }

  get applicationId() {
    return this._application ? this._application.id : this._applicationId;
  }

  set applicationId(value: number) {
    this._applicationId = value;
    this._applicationIdObservable.next(value);
  }

  get application() {
    return cloneDeep(this._application);
  }
  set application(value: Application) {
    this.setApplication(value, true);
  }

  setApplication(value: Application, notifyChanges: boolean) {
    this._application = value;
    this.applicationId = value?.id;
    if (notifyChanges) {
      this._applicationObservable.next(value);
    }
  }

  get applicationSettings() {
    return cloneDeep(this._applicationSettings);
  }
  set applicationSettings(value: ApplicationSetting[]) {
    this._applicationSettings = value;
    this._applicationSettingsObservable.next(value);
  }


  get modulesApplicationSettingsMap() {
    return cloneDeep(this._modulesApplicationSettingsMap);
  }
  set modulesApplicationSettingsMap(value: ModuleApplicationSettingMap[]) {
    this._modulesApplicationSettingsMap = value;
  }

  getSetting(settingName: string, moduleReferenceName: string): ApplicationSetting {
    if (moduleReferenceName) {
      const mapping = this._modulesApplicationSettingsMap.find(item =>
        item.name === settingName &&
        item.referenceName === moduleReferenceName);
      const setting = this._applicationSettings.find(item => item.name === mapping.mappedName);
      return cloneDeep(setting);
    } else {
      const setting = this._applicationSettings.find(item => item.name === settingName);
      return cloneDeep(setting);
    }
  }
}
