<div class="application-common-container">
  <a *ngIf="getIsDevelopment()"
     [routerLink]="['/management']"
     routerLinkActive="router-link-active">Management</a>
  <a *ngIf="getIsDevelopment()"
     [routerLink]="['/management/newversion']"
     routerLinkActive="router-link-active">New Management</a>
  <div class="inner-container">
    <div class="centered-header">
      <h1>Deployed applications</h1>
    </div>
    <ng-container *ngIf="!loading; else loadingData">
      <mat-card class="application-card"
                *ngFor="let app of applications"
                (click)="onClick(app)">
        <mat-card-title>[{{app.environment}}] {{ app.name }}</mat-card-title>
        <mat-card-subtitle>Version: {{app.version}}</mat-card-subtitle>
        <mat-card-content>{{app.description}}</mat-card-content>
      </mat-card>
    </ng-container>
    <ng-template #loadingData>
      <mat-spinner></mat-spinner>
    </ng-template>
  </div>
</div>