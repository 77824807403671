import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatexErrorService } from './datex-error-service';

@Component({
  selector: 'datex-engine-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  title = 'Application error';

  toggleErrorDetails = false;

  constructor(
    private router: Router,
    public errorSvc: DatexErrorService) { }

  ngOnInit() {
    if (!this.errorSvc.hasErrors) {
      this.router.navigate(['']);
    }
  }

  clearErrors() {
    this.errorSvc.clear();
    this.router.navigate(['']);
  }

}
