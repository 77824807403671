import { Component, OnInit } from '@angular/core';
import { ActiveEnvironmentComponent } from '../common/services/application.do';
import { EnvironmentComponentsService } from '../common/services/application.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {

  applications: ActiveEnvironmentComponent[];
  loading = false;

  constructor(private environmentComponentsService: EnvironmentComponentsService) { }

  ngOnInit(): void {
    this.loading = true;
    this.environmentComponentsService.getActives().subscribe(data => {
      this.applications = data;
      this.loading = false;
    });
  }

  getIsDevelopment() {
    return environment.production === false;
  }

  onClick(app: ActiveEnvironmentComponent) {
    window.location.href = app.websiteUrl;
  }
}
