import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { ApplicationsComponent } from './applications/applications.component';
import { ErrorComponent } from './error/error.component';
import { canViewStudio } from './common/guards/studio-access.guard';

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'home', component: ApplicationsComponent, canActivate: [MsalGuard]
  },
  {
    path: 'management',
    loadChildren: () => import('./management/management.module').then(m => m.ManagementModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'studio',
    loadChildren: () => import('./studio/studio.module').then(m => m.StudioModule),
    canActivate: [MsalGuard, canViewStudio]
  },
  { path: 'error', component: ErrorComponent },
  {
    /**
     * Needed for login on page load for PathLocationStrategy.
     * See FAQ for details:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular/docs/FAQ.md
     */
    path: 'auth',
    component: MsalRedirectComponent
  }
];

//const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // enableTracing: true,
    useHash: false,
    // Don't perform initial navigation in iframes
    // initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled'
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

