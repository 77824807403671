/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from './../environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    // the App ID representing the management studio app in Azure App Registration
    clientId: 'd42c106a-7dd1-4369-8057-30ace92e43d3',
    // the URL Azure AD will use to send the token back
    redirectUri: environment.authRedirectUri,
    postLogoutRedirectUri: environment.authPostLogoutRedirectUri
  },
  cache: {
    // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    cacheLocation: BrowserCacheLocation.LocalStorage,
    // Set this to 'true' if you are having issues on IE11 or Edge
    storeAuthStateInCookie: isIE
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  datexApplicationApi: {
    endpoint: environment.datexApplicationApiUrl,
    // .default is like saying all scope defined by the app registration
    // static scopes
    scopes: ['api://bcf89c41-81cc-49ef-b771-ed5ec9e1e5e4/.default'],
  },
  azureResourceManagerApi: {
    endpoint: 'https://management.azure.com/',
    scopes: ['https://management.azure.com/.default']
  },
  msGraphApi: {
    endpoint: 'https://graph.microsoft.com/',
    scopes: ['https://graph.microsoft.com/Group.Read.All', 'https://graph.microsoft.com/User.Read', 'https://graph.microsoft.com/User.ReadBasic.All', 'https://graph.microsoft.com/Application.Read.All']
  },
  integrationHubConfigurationApi: {
    endpoint: environment.integrationHubConfigApiUrl,
    scopes: environment.integrationHubConfigurationApiScopes
  },
  integrationHubApi: {
    endpoint: environment.integrationHubApiUrl,
    scopes: environment.integrationHubApiScopes
  },
  legacyConnectorConfigurationApi: {
    endpoint: environment.legacyConnectorsConfigApiUrl,
    scopes: environment.legacyConnectorsConfigApiScopes
  },
  integrationHubLoggingApiUrl: {
    endpoint: environment.integrationHubLoggingApiUrl,
    scopes: environment.integrationHubLoggingApiScopes
  },
  footPrintAdaptorApi: {
    endpoint: environment.footPrintAdaptorApiUrl,
    scopes: environment.footPrintAdaptorApiScopes
  },
  shopifyAdaptorApi: {
    endpoint: environment.shopifyAdaptorApiUrl,
    scopes: environment.shopifyAdaptorApiScopes
  },
  wooCommerceAdaptorApi: {
    endpoint: environment.wooCommerceAdaptorApiUrl,
    scopes: environment.wooCommerceAdaptorApiScopes
  },
  bigCommerceAdaptorApi: {
    endpoint: environment.bigCommerceAdaptorApiUrl,
    scopes: environment.bigCommerceAdaptorApiScopes
  },
  datexAlertRecommndedSolutionApi: {
    endpoint: environment.datexAlertRecommndedSolutionApiUrl,
    scopes: environment.datexAlertRecommndedSolutionApiScopes
  },
  kustoQueryApi: {
    endpoint: environment.kustoQueryApiUrl,
    scopes: environment.kustoQueryApiScopes
  },
  datexAlertApi: {
    endpoint: environment.datexAlertApiUrl,
    scopes: environment.datexAlertApiScopes
  },
  copilotApi: {
    endpoint: environment.copilotApiUrl,
    scopes: environment.copilotApiScopes
  },
  icmApi: {
    endpoint: environment.icmApiUrl,
    scopes: environment.icmApiScopes
  }
};
