import {
  Component,
  Inject
} from '@angular/core';

import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';

import { isNil, isEmpty } from 'lodash-es';

export interface IErrorDialogComponentData {
  title: string;
  message: string;
  messageList: string[];
  messageListDetailsSectionTitle: string;
  messageListDetails: { message: string; detail: string; }[];
}
@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html'
})
export class ErrorDialogComponent {

  toggleMessageListDetails = false;
  messageListDetails: { message: string, detail: string }[] = [];
  messageListDetailsJSON: unknown[] = [];

  constructor(
    private dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IErrorDialogComponentData) {

    this.messageListDetailsJSON = [];
    data.messageListDetails?.forEach(d => this.addMessageListDetails(d));

  }

  addMessage(message: string, detail: string) {
    if (this.messageListDetails?.length === 1) {
      this.data.message = 'The following errors occurred:';
      this.data.messageList = [this.messageListDetails[0]?.message];
    }
    this.data?.messageList.push(message);
    this.addMessageListDetails({ message, detail });
  }

  addMessageListDetails(detail: { message: string, detail: string }) {
    this.messageListDetails.push(detail);
    if (isNil(detail.detail) === false && isEmpty(detail.detail) === false) {
      try {
        const json = JSON.parse(detail.detail);
        this.messageListDetailsJSON.push(json);
      } catch {
        // unable to parse it, put the string directly
        this.messageListDetailsJSON.push(detail.detail);
      }
    }
  }

  hasMessageDetails() {
    return this.messageListDetails && this.messageListDetails.length > 0;
  }

  onCloseClicked() {
    this.dialogRef.close();
  }
}
