<div class="container">
  <div class="header">
    <img src="assets/img/wavelength_2024_logo_white.svg">
  </div>
  <div class="error-container">
    <div class="error-header">
      <div class="error-icon"><i class="icon icon-ic_fluent_error_circle_20_filled"></i></div>
      <div class="error-title">
        <h1>{{title}}</h1>
      </div>
    </div>
    <div class="error-message-container">
      <div class="error-main-message"
           *ngIf="errorSvc.errors.length === 1">{{errorSvc.errors[0].message}}</div>

      <div class="error-main-message"
           *ngIf="errorSvc.errors.length !== 1">The following errors occurred:</div>

      <ng-container *ngIf="errorSvc.errors.length !== 1">
        <div class="error-message-list-item"
             *ngFor="let err of errorSvc.errors">
          {{ err.message }}
        </div>
      </ng-container>

      <div class="error-message-details-container">

        <div (click)="toggleErrorDetails = !toggleErrorDetails"
             class="error-message-details-header">Technical details
          <div class="details-toggle">
            <i *ngIf="!toggleErrorDetails"
               class='icon icon-ic_fluent_chevron_down_20_filled'></i>
            <i *ngIf="toggleErrorDetails"
               class='icon icon-ic_fluent_chevron_up_20_filled'></i>
          </div>
        </div>

        <div class="error-message-details-dropdown"
             *ngIf="toggleErrorDetails">
          <div class="error-message-details"
               *ngFor="let err of errorSvc.errors">
            <div class="error-message-detail-title">{{ err.message }}</div>
            <div>{{ err.message }}</div>
            <div class="error-message-detail-content"
                 *ngIf="err.detail">
              <json-formatter [json]="err.detail"
                              [expand]="true"></json-formatter>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="error-button-group">
      <button class="submit-error-button"
              (click)="clearErrors()">OK</button>
    </div>
  </div>
</div>